import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { EditEntityModal } from "components/shared/Modals/EditEntityModal";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import * as commonService from "utility/CommonService.js";
import * as actions from "../../../redux/actions/WorkflowActions";
import { Button } from "react-bootstrap";
import { forError, forSuccess } from "utility/CommonService.js";
import EditScheduleModal from "./EditScheduleModal";
import {
  ACCESS_LEVEL,
  FLIGHTING_CONSTANTS,
  routes,
  PAGE_SIZE_CONSTANTS,
} from "utility/constants/constants";
import { ReplyOutlined } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import { StyledMenu } from "components/shared/header/components/StyledVisualWorkflowComponents";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { CalendarMonth } from "@mui/icons-material";
import { DeleteWorkflowModal } from "components/shared/Modals/DeleteWorkflowModal";
import { setPermissionTooltip, setPermissionOpacity } from "utility/utility";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updateWorkflow } from "redux/actions/WorkflowActions";
import { ENTITY_TYPES } from "utility/constants/constants";
import { ShareEntityModal } from "components/shared/Modals/ShareEntityModal/ShareEntityModal";
import { MenuItem } from "@mui/material";

const PauseSwitch = styled(Switch)(() => ({
  padding: 4,
  "& .MuiSwitch-track": {
    borderRadius: "20px",
    backgroundColor: "#E5E4E2",
    opacity: "1 !important",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    width: "20px",
    height: "20px",
  },
}));

export const WorkflowHeader = ({ workflow, dagInfo, projectAccessLevel }) => {
  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const history = useHistory();
  const workflowAccessToolTipTitle = setPermissionTooltip(ACCESS_LEVEL.EXECUTE, workflow?.access_level, "")
  const workflowAccessLevelOpacity = setPermissionOpacity(ACCESS_LEVEL.EXECUTE, workflow?.access_level)
  const projectAccessLevelOpacity = setPermissionOpacity(ACCESS_LEVEL.EDIT, projectAccessLevel)
  const menuOpen = Boolean(menuAnchor);
  const [showShareModal, setShowShareModal] = useState(false);
  const filterParams = {
    page: 1,
    pageSize: PAGE_SIZE_CONSTANTS.WORKFLOWS,
    sortOperator: "DESC",
    orderBy: "created_at",
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    if (e) e.stopPropagation();
    setMenuAnchor(null);
    dispatch(actions.getWorkflow(workflow.id));
  };

  const dispatch = useDispatch();

  const openScheduleModal = () => {
    setShowEditScheduleModal(true);
  };

  const triggerDag = async () => {
    dispatch(
      actions.runWorkflow({
        body: {
          is_paused: dagInfo?.is_paused,
        },
        id: workflow.id,
      })
    ).then((res) => {
      if (res.value && res.value.success) {
        forSuccess("Workflow run successful!", "Success");
      } else {
        forError("Workflow run failed", "Error");
      }
    });
  };

  const handleChange = async (event, workflow) => {
    let id = workflow.id;
    dagInfo.is_paused = !event.target.checked;
    dispatch(
      actions.pauseWorkflow({
        body: {
          paused: event.target.checked ? false : true,
        },
        id,
      })
    ).then((res) => {
      if (res.value && res.value.success) {
        dispatch(actions.getWorkflows());
        commonService.forSuccess("Workflow update successful!", "Success");
      } else {
        commonService.forError("Workflow update failed", "Error");
      }
    });
  };

  return (
    <div className="container">
      <div className="workflow_details_header">
        <div className="top_row">
          <div className="workflow_breadcrumb">
            <BreadCrumb
              currentLoc={workflow && workflow.name}
              previousLoc={FLIGHTING_CONSTANTS.WORKFLOWS}
              goBack={() => history.push(routes.WORKFLOWS)}
            />
          </div>
          <div className="top_row_right">
            {workflow && <Tooltip
              title={
                workflowAccessToolTipTitle
              }
              placement="top"
            >
              <span>
                <Button
                  className="btn_small"
                  onClick={() => triggerDag()}
                  style={{
                    opacity:workflowAccessLevelOpacity,
                  }}
                  disabled={workflow?.access_level === ACCESS_LEVEL.VIEW}
                >
                  <div className="trigger_dag">
                    <div className="circle_style">
                      <div className="arrow_style"></div>
                    </div>
                    Trigger Dag
                  </div>
                </Button>
              </span>
            </Tooltip>}
            {workflow && <Tooltip
              title={
                workflowAccessLevelOpacity === .5 && projectAccessLevelOpacity === .5
                ?"You need execute or edit workflow access and edit project access"
                :workflowAccessLevelOpacity === .5? "You need execute or edit workflow access"
                :projectAccessLevelOpacity === .5? "You need edit project access"
                :""
              }
              placement="top"
            >
              <span>
                <Button
                  onClick={() => openScheduleModal()}
                  className="btn_small"
                  style={{
                    opacity:Math.min(workflowAccessLevelOpacity, projectAccessLevelOpacity),
                  }}
                  disabled={Math.min(workflowAccessLevelOpacity, projectAccessLevelOpacity) === .5}
                >
                  <div className="organize_btn">
                    <CalendarMonth height="25px"></CalendarMonth>
                    Schedule
                  </div>
                </Button>
              </span>
            </Tooltip>}
            <span>
              <Button
                className="btn_small"
                id="white_btn"
                onClick={(event) => handleMenuClick(event)}
                disabled={false}
              >
                ...
              </Button>
            </span>
          </div>
        </div>
        <div
          className="active-toggle"
          style={{
            paddingLeft: "40px",
            opacity: workflowAccessLevelOpacity,
          }}
        >
          <Tooltip
            title={
              workflowAccessToolTipTitle
            }
            placement="top"
          >
            <div className="active-tooltip">
              <h5 className="active-text">Active</h5>
              <PauseSwitch
                color="success"
                checked={!dagInfo?.is_paused}
                onChange={(e) => {
                  handleChange(e, workflow);
                }}
                name="workflowRunState"
                disabled={workflow?.access_level === ACCESS_LEVEL.VIEW}
              />
            </div>
          </Tooltip>
        </div>
        <div className="show_tool">
          <StyledMenu
            id="long-menu-button"
            disableScrollLock={true}
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <DeleteWorkflowModal
              entity={workflow}
              handleMenuClose={handleMenuClose}
            >
              <ListItemIcon>
                <div className="entity_menu_item">
                  <DeleteIcon fontSize="small" />
                  <p>Delete</p>
                </div>
              </ListItemIcon>
            </DeleteWorkflowModal>
            <MenuItem
              style={{
                opacity: setPermissionOpacity(
                  ACCESS_LEVEL.EXECUTE,
                  workflow?.access_level
                ),
              }}
              disabled={workflow?.access_level !== ACCESS_LEVEL.EDIT}
              dense={true}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowShareModal(true);
                handleMenuClose();
                e.stopPropagation();
              }}
            >
              <ListItemIcon className="share">
                <div className="entity_menu_item">
                  <ReplyOutlined
                    sx={{
                      transform: "scaleX(-1)",
                      width: "25px",
                      height: "25px",
                    }}
                  />
                  <p>Share</p>
                </div>
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              dense={true}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowEditModal(true);
                handleMenuClose();
                e.stopPropagation();
              }}
            >
              <ListItemIcon className="edit">
                <div className="entity_menu_item">
                  <img
                    src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                    alt="Edit Entity"
                    className="cursor-pointer optionsIcon"
                  />
                  <p>Edit</p>
                </div>
              </ListItemIcon>
            </MenuItem>
          </StyledMenu>
          <EditScheduleModal
            show={showEditScheduleModal}
            setShowEditScheduleModal={setShowEditScheduleModal}
            id={workflow?.id}
            projectId={workflow?.project_id}
            accessLevel={workflow?.access_level}
          />
          <EditEntityModal
            entity={workflow}
            entityType={ENTITY_TYPES.WORKFLOW}
            updateEntity={updateWorkflow}
            handleMenuClose={handleMenuClose}
            showModal={showEditModal}
            setShowModal={setShowEditModal}
          />
          <ShareEntityModal
            entity={workflow}
            entityType={ENTITY_TYPES.WORKFLOW}
            createEntityShare={actions.createWorkflowShare}
            getEntities={actions.getWorkflows}
            handleMenuClose={handleMenuClose}
            entityLimit={PAGE_SIZE_CONSTANTS.WORKFLOWS}
            filterParams={filterParams}
            showModal={showShareModal}
            setShowModal={setShowShareModal}
          />
        </div>
      </div>
    </div>
  );
};
