import React, { useState } from "react";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import EditScheduleModal from "components/Workflows/WorkflowDetails/EditScheduleModal";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { IosShareSharp } from "@mui/icons-material";
import * as actions from "../../../redux/actions/WorkflowActions";
import * as commonService from "utility/CommonService.js";
import { DeleteWorkflowModal } from "components/shared/Modals/DeleteWorkflowModal";
import { ShareEntityModal } from "components/shared/Modals/ShareEntityModal/ShareEntityModal";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { StyledMenu } from "components/shared/header/components/StyledVisualWorkflowComponents";
import { MenuItem } from "@mui/material";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Tooltip from "@mui/material/Tooltip";
import {
  ENTITY_TYPES,
  PAGE_SIZE_CONSTANTS,
  ACCESS_LEVEL,
} from "utility/constants/constants";
import { getHighestAccessLevel } from "utility/utility";
import {
  getWorkflows,
  createWorkflowShare,
} from "redux/actions/WorkflowActions";
import PublishVisualWorkflowModal from "./PublishVisualWorkflowModal";
import PublishConfirmationModal from "./PublishConfirmationModal";
import { setPermissionOpacity } from "utility/utility";

const VisualWorkflowsHeader = ({
  user,
  workflow,
  nodes,
  edges,
  accessLevel,
}) => {
  const dispatch = useDispatch();
  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
  const menuOpen = Boolean(menuAnchor);
  const workflowAccessLevelOpacity = setPermissionOpacity(
    ACCESS_LEVEL.EXECUTE,
    workflow?.access_level
  );
  const projectAccessLevelOpacity = setPermissionOpacity(
    ACCESS_LEVEL.EDIT,
    accessLevel
  );

  const [filterParams] = useState({
    page: 1,
    pageSize: PAGE_SIZE_CONSTANTS.ROWS,
    sortOperator: "DESC",
    orderBy: "created_at",
  });
  const handleMenuClick = (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = (e) => {
    if (e) e.stopPropagation();
    setMenuAnchor(false);
  };

  const handleSave = () => {
    dispatch(
      actions.saveWorkflow({
        body: {
          nodes: nodes,
          edges: edges,
        },
        id: workflow.id,
      })
    ).then((res) => {
      if (res.value?.success) {
        commonService.forSuccess("Workflow Saved Successfully", "Success");
      }
    });
  };

  const handlePublish = () => {
    setShowPublishModal(true);
  };

  return (
    <div className="visual_workflow_header">
      <div className="top_row">
        <div className="workflow_breadcrumb">
          <Link to="/workflows" className="back_link">
            <ExpandCircleDownIcon className="back-circle-icon" />
            <div className="list_bread_crumb">Workflows</div>
          </Link>
          <ArrowForwardIosIcon />
          <div className="detail_bread_crumb">Designer</div>
          <ArrowForwardIosIcon />
          <div className="title_bread_crumb">{workflow?.name}</div>
        </div>
        <div className="top_row_right">
          <Tooltip
            title={
              workflowAccessLevelOpacity === 0.5 &&
              projectAccessLevelOpacity === 0.5
                ? "You need execute or edit workflow access and edit project access"
                : workflowAccessLevelOpacity === 0.5
                ? "You need execute or edit workflow access"
                : projectAccessLevelOpacity === 0.5
                ? "You need edit project access"
                : ""
            }
            placement="top"
          >
            <CalendarMonthOutlinedIcon
              id={
                Math.min(
                  workflowAccessLevelOpacity,
                  projectAccessLevelOpacity
                ) === .5
                  ? "calendar_icon_inactive"
                  : "calendar_icon"
              }
              style={{
                opacity: Math.min(
                  workflowAccessLevelOpacity,
                  projectAccessLevelOpacity
                ),
              }}
              onClick={() =>
                Math.min(
                  workflowAccessLevelOpacity,
                  projectAccessLevelOpacity
                ) === 1
                  ? setShowEditScheduleModal(true)
                  : null
              }
            />
          </Tooltip>
          <Tooltip
            title={
              workflowAccessLevelOpacity === 0.5 &&
              projectAccessLevelOpacity === 0.5
                ? "You need execute or edit workflow access and edit project access"
                : workflowAccessLevelOpacity === 0.5
                ? "You need execute or edit workflow access"
                : projectAccessLevelOpacity === 0.5
                ? "You need edit project access"
                : ""
            }
            placement="top"
          >
            <span>
              <button
                className="btn_small"
                id='purple_btn'
                onClick={handlePublish}
                style={{
                  opacity: Math.min(
                    workflowAccessLevelOpacity,
                    projectAccessLevelOpacity
                  ),
                }}
                disabled={Math.min(workflowAccessLevelOpacity, projectAccessLevelOpacity) === .5}
              >
                publish
              </button>
            </span>
          </Tooltip>

          <span>
            <button
              className="btn_small"
              id="white_btn_long"
              onClick={handleSave}
              disabled={false /*["view"].includes(accessLevel)*/}
            >
              Save as a Draft
            </button>
          </span>
          <span>
            <button
              className="btn_small"
              id="white_btn_short"
              onClick={(event) => handleMenuClick(event)}
              disabled={false /*["view"].includes(accessLevel)*/}
            >
              ...
            </button>
          </span>
        </div>
      </div>
      <div className="bottom_row">
        <Tooltip title={"Coming soon"} placement="top">
          <div className="test_run">
            <PlayCircleFilledWhiteOutlinedIcon></PlayCircleFilledWhiteOutlinedIcon>
            Test Run
          </div>
          {/* <div className="zoom">
            <div className="zoom_adjust" onClick={() => setZoom(zoom - 10)}>
              -
            </div>
            <div className="zoom_value"> {`${zoom}%`}</div>
            <div className="zoom_adjust" onClick={() => setZoom(zoom + 10)}>
              +
            </div>
          </div> */}
        </Tooltip>
      </div>
      <EditScheduleModal
        show={showEditScheduleModal}
        setShowEditScheduleModal={setShowEditScheduleModal}
        id={workflow?.id}
        projectId={workflow?.project_id}
        accessLevel={
          workflow ? getHighestAccessLevel(workflow, user) : ACCESS_LEVEL.VIEW
        }
      />
      <div className="show_tool">
        <StyledMenu
          id="long-menu-button"
          disableScrollLock={true}
          anchorEl={menuAnchor}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem
            dense={true}
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => {
              setShowShareModal(true);
              handleMenuClose();
              e.stopPropagation();
            }}
          >
            <ListItemIcon className="share">
              <div className="entity_menu_item">
              <IosShareSharp fontSize="small" />
                <p>Share</p>
              </div>
            </ListItemIcon>
          </MenuItem>
          <DeleteWorkflowModal
            entity={workflow}
            handleMenuClose={handleMenuClose}
          >
            <ListItemIcon className="delete">
              <div className="entity_menu_item">
               <DeleteIcon fontSize="small" />
                <p> Delete</p>
              </div>
            </ListItemIcon>
          </DeleteWorkflowModal>
        </StyledMenu>
      </div>
      <ShareEntityModal
        entity={workflow}
        entityType={ENTITY_TYPES.WORKFLOW}
        createEntityShare={createWorkflowShare}
        getEntities={getWorkflows}
        handleMenuClose={handleMenuClose}
        entityLimit={PAGE_SIZE_CONSTANTS.WORKFLOWS}
        filterParams={filterParams.filterParams}
        showModal={showShareModal}
        setShowModal={setShowShareModal}
      />
      <PublishVisualWorkflowModal
        show={showPublishModal}
        setShowPublishModal={setShowPublishModal}
        projectId={workflow?.project_id}
        id={workflow?.id}
        setShowPublishConfirmation={setShowPublishConfirmation}
        workflow={workflow}
        nodes={nodes}
        edges={edges}
        accessLevel={
          accessLevel ? accessLevel : ACCESS_LEVEL.NONE
        }
      />
      <PublishConfirmationModal
        show={showPublishConfirmation}
        setShowPublishConfirmation={setShowPublishConfirmation}
        setShowShareModal={setShowShareModal}
        workflow={workflow}
      />
    </div>
  );
};

export default VisualWorkflowsHeader;
