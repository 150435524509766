import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from 'react-bootstrap';
import { getEnvVariables } from 'redux/actions/ProjectsActions';
import { updateSchedule } from 'redux/actions/WorkflowActions';
import * as commonService from "utility/CommonService.js";
import ScheduleInput from './ScheduleInput';
import { WORKFLOW_VARIABLES } from 'utility/constants/constants';
import { ACCESS_LEVEL } from "utility/constants/constants";

const EditScheduleModal = ({show, setShowEditScheduleModal, id, projectId, accessLevel, projectAccessLevel}) => {
  const dispatch = useDispatch();
  const [cronExpression, setCronExpression] = useState('');
  const { envVariables } = useSelector((state) => state.projectsReducer);

  useEffect(() => {
    if (id && projectAccessLevel === ACCESS_LEVEL.EDIT) {
      const variableKey = WORKFLOW_VARIABLES.LUDIS_SCHEDULE + id.substring(0,8)
      dispatch(
        getEnvVariables({
          id: projectId,
          key: variableKey
        })
      )
    };
  }, [id, dispatch, projectId, projectAccessLevel])

  // set current schedule
  useEffect(() => {
    if (envVariables.length > 0) {
      setCronExpression(envVariables[0].value)
    }

  }, [envVariables])

  const updateScheduleVariable = () => {
    const varId = envVariables[0].id
    dispatch(
      updateSchedule({
        body: {
          value: cronExpression,
          project_id: projectId,
          var_id: varId
        },
          id: id
        })
    ).then((res) => {
      if (res.value && res.value.success) {
        commonService.forSuccess("Updated Schedule Successfully", "Success");
        setShowEditScheduleModal(false);
      } else {
        commonService.forError("Failed to Update Workflow Schedule", "Error");
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    updateScheduleVariable()
  }

  return (
      <Modal
        className="import_modal"
        size="l"
        show={show}
        onHide={() => setShowEditScheduleModal(false)}
        animation={false}
        scrollable={true}
      >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center">
          <h2>Update Schedule</h2>
        </div>
      </Modal.Header>
      <Modal.Body>
      <div className="col-md-12">
        <ScheduleInput setCronExpression = {setCronExpression} cronExpression = {cronExpression}></ScheduleInput>
      </div>
      </Modal.Body>
      <Modal.Footer>
          <Button
              onClick={handleSubmit}
              type="submit"
              value="Submit"
              className="btn float-right"
              disabled={!ACCESS_LEVEL.ALL_EXECUTE.includes(accessLevel)}
          >
              Update
          </Button>
      </Modal.Footer>
    </Modal>
  )

}

export default EditScheduleModal